.game-label-style {
  cursor: text;  
  position: relative;  
  font-size: 1.8vw;  
}

.game-input-style {  
  cursor: text;  
  position: relative;  
  font-size: 1.6vw;
  color: #777;  
}