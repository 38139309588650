.label-style {
  cursor: text;
  left: 1vh;
  top: 1vh;
  position: relative;
  color: #c9ccd1;
}

.input-style {  
  cursor: text;
  left: 1vh;
  top: 1vh;
  position: relative;
  color: #c9ccd1;
}